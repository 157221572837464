import React, { useState, useEffect } from "react";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import Spinner from "../../spinner/Spinner";
import AxiosApiCall from "../../../api/AxiosApiCall";

const CreateProductStockForm = ({ formData, createStock, handleNext }) => {
    const [formValues, setFormValues] = useState({
        store_id: formData.store_id || "",
        product_id: formData.product_id || "",
        description: formData.description || "",
        price: formData.price ? formData.price.toString() : "",
        batch_number: formData.batch_number ? formData.batch_number.toString() : "",
    });

    const [stores, setStores] = useState([]);
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [stock_id, setStockId] = useState(""); // State to store the stock ID
    const [product_category, setProductCategory] = useState(""); // **NEW: State to store the product category**

    useEffect(() => {
        const fetchStoresAndProducts = async () => {
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;

                const [storesResponse, productsResponse] = await Promise.all([
                    axios.get(ApiServices.getAllStore, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(ApiServices.getProducts, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);

                setStores(storesResponse.data.data);
                setProducts(productsResponse.data.data);
            } catch (error) {
                console.error(error);
                toast.error("Error fetching stores or products.");
            } finally {
                setLoading(false);
            }
        };

        fetchStoresAndProducts();
    }, []);

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.store_id) newErrors.store_id = "Store field is required.";
        if (!formValues.description) newErrors.description = "Description field is required.";
        if (!formValues.product_id) newErrors.product_id = "Product field is required.";

        if (formValues.price === "") {
            newErrors.price = "Price is required.";
        } else if (isNaN(formValues.price) || parseFloat(formValues.price) <= 0) {
            newErrors.price = "Price must be a positive number.";
        }

        if (formValues.batch_number && formValues.batch_number < 0) {
            newErrors.batch_number = "Batch number must be a positive number.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!validateForm()) return;

    //     try {
    //         const authData = JSON.parse(localStorage.getItem("auth"));
    //         const token = authData?.token;

    //         const response = await axios.post(ApiServices.createProductStock, formValues, {
    //             headers: { Authorization: `Bearer ${token}` },
    //         });

    //         // const { stock_id, product_category } = response.data.data;
    //         // const stock_id = response.data.data.stock_id;
    //         // const product_category = response.data.data.product_category;
    //         // const { stock_id, product_category } = response.data.data;//this works fine
    //         const { stock_id, product_category } = response.data.data;
    //         console.log("API Response for Stock Creation:", response.data.data.stock_id);
    //         console.log("API Response for Stock id:", response);
    //         console.log("API Response for Stock  product category :", response.data.data.product_category);

    //         // setStockId(stock_id); // Store the stock ID for later use
    //         // setProductCategory(product_category); // **NEW: Store the product category**
    //         // // Call createStock with the received values
    //         createStock(stock_id, product_category);

    //         // console.log("Step 2 - Product Stock Creation Data:", formValues);
    //         // console.log("Created stock with ID:", stock_id);
    //         // console.log("Product Category:", product_category); 

    //         setProductCategory(product_category);
    //         //pass the stock here
    //         // handleNext({ stock_id, product_category });
    //         handleNext(stock_id, product_category, formValues);
    //         // toast.success("Product stock created successfully!");
    //         toast.success(response.data.message);

    //     } catch (error) {
    //         console.error("Error creating product stock:", error);
    //     }
    // };

    //axios api encryption call
    const axios = AxiosApiCall();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;

            const response = await axios.post(ApiServices.createProductStock, formValues, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Check if the response status is 200 before proceeding
            if (response.status === 200) {
                const { stock_id, product_category } = response.data.data;

                setStockId(stock_id);
                setProductCategory(product_category);

                // Pass the stock ID and product category to the next step
                createStock(stock_id, product_category);
                handleNext(stock_id, product_category, formValues);

                toast.success(response.data.message);
            } else {
                // If status is not 200, show an error message and do not proceed
                toast.error("Failed to create product stock. Please try again.");
            }
        } catch (error) {
            console.error("Error creating product stock:", error);
            toast.error("An error occurred while creating product stock.");
        }
    };



    const handleFieldChange = (e) => {
        const { name, value } = e.target;

        setFormValues((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <>
            {loading ? (
                <Spinner loading={loading} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <h2 className="text-orange1">Create Product Stock</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="w-full">
                            <label htmlFor="store_id" className="block mb-2 text-sm font-medium text-gray-900">Store</label>
                            <select
                                id="store_id"
                                name="store_id"
                                value={formValues.store_id}
                                onChange={handleFieldChange}
                                className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="" disabled>Select Store</option>
                                {stores.length === 0 ? (
                                    <option value="" disabled>No stores available</option>
                                ) : (
                                    stores.map(store => (
                                        <option key={store.store_id} value={store.store_id}>
                                            {store.name}
                                        </option>
                                    ))
                                )}
                            </select>
                            {errors.store_id && <p className="text-red1">{errors.store_id}</p>}
                        </div>

                        <div className="w-full">
                            <label htmlFor="product_id" className="block mb-2 text-sm font-medium text-gray-900">Product</label>
                            <select
                                id="product_id"
                                name="product_id"
                                value={formValues.product_id}
                                onChange={handleFieldChange}
                                className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="" disabled>Select Product</option>
                                {products.length === 0 ? (
                                    <option value="" disabled>No products available</option>
                                ) : (
                                    products.map(product => (
                                        <option key={product.product_id} value={product.product_id}>
                                            {product.name}
                                        </option>
                                    ))
                                )}
                            </select>
                            {errors.product_id && <p className="text-red1">{errors.product_id}</p>}
                        </div>

                        <div className="w-full">
                            <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900">Price</label>
                            <input
                                type="text"
                                id="price"
                                name="price"
                                value={formValues.price}
                                onChange={handleFieldChange}
                                className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                placeholder="Enter price"
                            />
                            {errors.price && <p className="text-red1">{errors.price}</p>}
                        </div>

                        <div className="w-full">
                            <label htmlFor="batch_number" className="block mb-2 text-sm font-medium text-gray-900">Batch Number</label>
                            <input
                                type="number"
                                id="batch_number"
                                name="batch_number"
                                value={formValues.batch_number || ""}
                                onChange={handleFieldChange}
                                className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                placeholder="Enter batch number"
                            />
                            {errors.batch_number && <p className="text-red1">{errors.batch_number}</p>}
                        </div>

                    </div>
                    <div className="w-full mt-4">
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Brief Description</label>
                        <textarea
                            name="description"
                            id="description"
                            value={formValues.description}
                            onChange={handleFieldChange}
                            rows={6}
                            className={`bg-gray-50 border ${errors.description ? 'border-red-500' : 'border-dGrey1'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                            placeholder="Enter brief description here"
                        />
                        {errors.description && <p className="text-red1 text-sm">{errors.description}</p>}
                    </div>

                    <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                        <button type="submit" className="text-dGrey3 bg-darkGreen3 border-0 py-2 px-6 focus:outline-none hover:bg-darkGreen2 rounded-lg text-sm text-white">
                            Next
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default CreateProductStockForm;

