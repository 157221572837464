import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const AddProduct = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [product_category_id, setProductCategory_id] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [sub_category_id, setSubcategory_id] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isSerialize, setIsSerialize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubcategoryLoading, setIsSubcategoryLoading] = useState(false);
  const [loadProductCat, setLoadProductCat] = useState(false);

  const navigateTo = useNavigate();

  // Fetch categories once the component mounts
  useEffect(() => {
    const fetchCategoryList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        const response = await axios.get(ApiServices.getCategory, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        if (status === 200 && Array.isArray(data)) {
          setCategories(data);
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryList();
  }, []);

  // Fetch subcategories based on selected category
  const fetchSubCategoryList = async (category_id) => {
    setIsSubcategoryLoading(true);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      const response = await axios.get(ApiServices.getSubCategoryById, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { category_id },
        withCredentials: true,
      });

      const { status, message, data } = response.data;
      console.log("subcategory response", data);
      if (status === 200 && Array.isArray(data)) {
        setSubCategory(data);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setIsSubcategoryLoading(false);
    }
  };

  // Fetch products based on selected subcategory
  const fetchProductBySubCategoryId = async (sub_category_id) => {
    if (!sub_category_id) return;
    setLoadProductCat(true);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      const response = await axios.get(ApiServices.getProductsBySubCategoryId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { sub_category_id },
        withCredentials: true,
      });

      const { status, message, data } = response.data;
      console.log("product category response", data);
      if (status === 200) {
        setProduct(data);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoadProductCat(false);
    }
  };

  //get sub category once category list is fetch
  useEffect(() => {
    if (category_id) fetchSubCategoryList(category_id);
  }, [category_id]);

  //get product by sub category id once sub category api is called
  useEffect(() => {
    if (sub_category_id) fetchProductBySubCategoryId(sub_category_id);
  }, [sub_category_id]);

  //handle chnages in category
  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    // console.log("selcetd categoey id ", selectedCategory);
    setCategory_id(selectedCategory);
    setSubcategory_id("");
    setProduct([]);
    if (selectedCategory) {
      fetchSubCategoryList(selectedCategory);
    }
  };

  const handleSubcategoryChange = (e) => {
    const selectedSubCategory = e.target.value;
    // console.log("selcetd sub categoey id ", selectedSubCategory);
    setSubcategory_id(selectedSubCategory);
    fetchProductBySubCategoryId(selectedSubCategory);
  };

  const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    // console.log("selcetd product id ", selectedProduct);
    setProductCategory_id(selectedProduct);
    setSelectedProduct(selectedProduct);
  };


  //axios api encryption call
  const axios = AxiosApiCall();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      if (
        !name ||
        !product_category_id ||
        !category_id ||
        !sub_category_id ||
        !description
      ) {
        toast.error("All fields are required.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);
    const formData = {
      name,
      product_category_id,
      category_id,
      sub_category_id,
      description,
      is_serialized: isSerialize
    };
      // console.log("this is my form data",formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(ApiServices.addProducts, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/products");
        toast.success(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendors Product
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Create</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter product name"
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="category_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Category
                      </label>
                      <select
                        id="category_id"
                        name="category_id"
                        value={category_id}
                        onChange={handleCategoryChange}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          Select Category
                        </option>
                        {categories.map((category, index) => (
                          <option key={index} value={category.category_id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-2">
                    <div className="w-full">
                      <label
                        htmlFor="sub_category_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Sub Category
                      </label>
                      <select
                        id="sub_category_id"
                        name="sub_category_id"
                        value={sub_category_id}
                        onChange={handleSubcategoryChange}
                        disabled={!category_id}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          {isSubcategoryLoading
                            ? "Loading..."
                            : "Select sub Category"}
                        </option>
                        {subCategory.map((sub, index) => (
                          <option key={index} value={sub.sub_category_id}>
                            {sub.sub_category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="store_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Product Category
                      </label>
                      <select
                        id="product_category_id"
                        name="product_category_id"
                        value={selectedProduct}
                        onChange={handleProductChange}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          {loadProductCat
                            ? "Loading stores..."
                            : "Select Product Category"}
                        </option>
                        {product.map((prod, index) => (
                          <option key={index} value={prod.product_category_id}>
                            {prod.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>


                  <div className="w-full my-2 mx-2">
                    <input
                      type="checkbox"
                      id="is_serialize"
                      name="is_serialize"
                      checked={isSerialize}
                      onChange={(e) => setIsSerialize(e.target.checked)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label
                      htmlFor="is_serialize"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Is Serialize <small className="text-red1  text-xs px-1"> (This is optional)</small>
                    </label>
                  </div>

                  <div className="grid grid-cols-12 sm:grid-cols-12 gap-6 mt-4">
                    <div className="col-span-12">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Brief Description
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        value={description}
                        rows={6}
                        onChange={(e) => setDescription(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter brief description here"
                      />
                    </div>


                    

                  </div>

                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/products"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Add Product"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default AddProduct;
