import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as PencilFill } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as ArrowLeftCircleLeftFill } from "bootstrap-icons/icons/arrow-left-circle-fill.svg";
import { ReactComponent as ArrowRightCircleLeftFill } from "bootstrap-icons/icons/arrow-right-circle-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import Spinner from "../spinner/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const AssignRoleToAdmin = () => {
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [adminsPerPage] = useState(12);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [selectedAdmins, setselectedAdmins] = useState([]);

    const navigateTo = useNavigate();
    const location = useLocation();
    const role = location.state?.role;

    //   console.log("this is the role name ", role);
    const [role_id] = useState(role?.role_id || "");
    const [roleName] = useState(role?.name || "");

    useEffect(() => {
        const fetchAdminList = async () => {
            setLoading(true);
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                if (!token) {
                    throw new Error("No access token found.");
                }
                const response = await axios.get(ApiServices.getAdmin, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                });

                const { status, message, data } = response.data;
                // console.log("i want to assign this admin a role ", data);
                if (status === 200) {
                    setAdmins(data);
                    // toast.success(message, { toastId: "admin-fetch-success" });
                } else {
                    handleError({ response: { status, data: { message } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading(false);
            }
        };

        fetchAdminList();
    }, []);

    const validateForm = () => {
        if (selectedAdmins.length === 0 || !role_id) {
            toast.error("Please select at one admin.");
            return false;
        }
        return true;
    };

    const handleCheckboxChange = (adminId) => {
        const adminIdInt = parseInt(adminId, 10); // Convert adminId to integer
        setselectedAdmins((prevSelected) =>
            prevSelected.includes(adminIdInt)
                ? prevSelected.filter((id) => id !== adminIdInt)
                : [...prevSelected, adminIdInt]
        );
    };


    //axios api encryption call
    const axios = AxiosApiCall();
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        const formData = {
            admin_id:
                selectedAdmins.length === 1 ? selectedAdmins[0] : selectedAdmins, // Handle single admin or multiple admins
            role_id: role_id,
        };
        // console.log("data from the form", formData);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            if (!token) {
                throw new Error("No access token found.");
            }

            const response = await axios.post(
                ApiServices.assignPermissionToRole,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            const { status, message } = response.data;
            if (status === 200) {
                navigateTo("/admin/roles/list");
                toast.success(message, { toastId: "admin-updated-successfully" });
            } else {
                handleError({ response: { status, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    const handleFilter = (e) => {
        setFilterText(e.target.value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const filteredAdmins = admins.filter((item) =>
        item.fullname.toLowerCase().includes(filterText.toLowerCase().trim())
    );

    const indexOfLastAdmin = currentPage * adminsPerPage;
    const indexOfFirstAdmin = indexOfLastAdmin - adminsPerPage;
    const currentadmins = filteredAdmins.slice(
        indexOfFirstAdmin,
        indexOfLastAdmin
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const hasNextPage = () => indexOfLastAdmin < filteredAdmins.length;
    const hasPrevPage = () => currentPage > 1;

    //   const handleCheckboxChange = (adminId) => {
    //     setselectedAdmins((prevSelected) =>
    //       prevSelected.includes(adminId)
    //         ? prevSelected.filter((id) => id !== adminId)
    //         : [...prevSelected, adminId]
    //     );
    //   };

    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section
                        className="w-full pl-[3%] pr-[3%] pt-3 my-3"
                        style={{ backgroundColor: "#F7F7F7" }}
                    >
                        <nav className="bg-gray-200 py-0 px-1 rounded-lg ">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-orange1 pl-1">
                                        Assign Role To Admin
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                {roleName && (
                                    <li className="flex items-center">
                                        <p className="text-gray-600 text-black1">
                                            <span className="font-medium pl-1">{roleName}</span>
                                        </p>
                                    </li>
                                )}
                            </ol>
                        </nav>
                    </section>
                    <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
                        <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
                            <input
                                type="search"
                                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Search"
                                value={filterText}
                                onChange={handleFilter}
                            />
                        </div>
                    </div>
                    {loading ? (
                        <Spinner loading={loading} />
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <section className="text-gray-600 body-font">
                                <div className="container px-1 py-2 mx-auto">
                                    <div className="flex flex-wrap -m-2">
                                        {currentadmins.length > 0 ? (
                                            currentadmins.map((admin, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="p-2 lg:w-1/3 md:w-1/2 w-full"
                                                    >
                                                        <div className="h-full flex items-center border-darkGreen3 border p-4 rounded-lg">
                                                            <div className="flex-grow">
                                                                <h2 className="flex justify-end  text-darkGreen3 title-font font-medium">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="ml-4 "
                                                                        checked={selectedAdmins.includes(
                                                                            admin.admin_id
                                                                        )}
                                                                        onChange={() =>
                                                                            handleCheckboxChange(admin.admin_id)
                                                                        }
                                                                    />
                                                                </h2>

                                                                <p className="text-gray-500 flex items-center">
                                                                    <span className="ml-2 text-dash5 uppercase ">
                                                                        {admin.fullname}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="w-full text-center">No admins found</div>
                                        )}
                                    </div>

                                    <div className="flex flex-col gap-3 sm:flex-row justify-between mt-4">
                                        <div className="flex items-center gap-3">
                                            <button
                                                type="button"
                                                disabled={!hasPrevPage()}
                                                onClick={() => paginate(currentPage - 1)}
                                                className={`text-dGrey3 bg-black1  hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 ${!hasPrevPage() && "opacity-50"
                                                    }`}
                                            >
                                                <ArrowLeftCircleLeftFill />
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                disabled={!hasNextPage()}
                                                onClick={() => paginate(currentPage + 1)}
                                                className={`text-dGrey3 bg-black1  hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 ${!hasNextPage() && "opacity-50"
                                                    }`}
                                            >
                                                Next
                                                <ArrowRightCircleLeftFill />
                                            </button>
                                        </div>
                                        <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                                            <Link
                                                to="/admin/roles/list"
                                                className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                                            >
                                                <List className="mr-2" />
                                                Back To List
                                            </Link>
                                            <button
                                                type="submit"
                                                className={`text-white bg-orange1 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                                                    }`}
                                                disabled={loading}
                                            >
                                                {loading ? "Loading..." : "   Assign Selected admins"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    )}
                </div>
            </MainLayouts>
        </div>
    );
};

export default AssignRoleToAdmin;
