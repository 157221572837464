// import React, { useState, useEffect } from "react";
// import MainLayouts from "../../Layout/MainLayouts";
// import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import axios from "axios";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import ApiServices from "../../api/ApiServices";
// import { toast } from "react-toastify";
// import Spinner from "../spinner/Spinner";
// import { handleError } from "../../components/utils/AuthenticationCheck";

// const EditProductStock = () => {
//   const [firstname, setFirstName] = useState("");
//   const [lastname, setLastName] = useState("");
//   const [vendor_id, setVendor_id] = useState("");
//   const [phone_number, setPhoneNumber] = useState("");
//   const [email, setEmail] = useState("");
//   const [status, setStatus] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [isProductLoading, setIsProductLoading] = useState(true);
//   const [StoreLoading, setStoreLoading] = useState(true);

//   const navigateTo = useNavigate();

//   const location = useLocation();
//   const vendorId = location.state?.vendorId;
//   console.log("This is vendor data", vendorId);

//   // Set initial state from location.state
//   useEffect(() => {
//     if (vendorId) {
//       // console.log("Set initial state from location", productStock);
//       setVendor_id(vendorId.vendor_id || "");
//       setFirstName(vendorId.firstname || "");
//       setLastName(vendorId.lastname || "");
//       setPhoneNumber(vendorId.lastname || "");
//       setEmail(vendorId.email || "");
//       setStatus(vendorId.status || "");
//     }
//   }, [vendorId]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validateForm = () => {
//       // const batch_number = null;
//       if (!vendor_id || !status) {
//         toast.error("All fields are required.");
//         // return false;
//       }
//       return true;
//     };

//     if (!validateForm()) return;

//     setLoading(true);
//     const formData = {
//       status,
//       vendor_id,
//     };
//     console.log("this is my form data", formData);
//     try {
//       const authData = JSON.parse(localStorage.getItem("auth"));
//       const token = authData?.token;
//       if (!token) {
//         throw new Error("No access token found.");
//       }

//       const response = await axios.put(ApiServices.updateVendor, formData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//         withCredentials: true,
//       });

//       const { status, message } = response.data;
//       if (status === 200) {
//         navigateTo("/admin/vendor/list");
//         toast.success(message);
//         setStatus("");
//         setVendor_id("");
//       } else {
//         handleError({ response: { status, data: { message } } }, navigateTo);
//       }
//     } catch (error) {
//       handleError(error, navigateTo);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <MainLayouts>
//         <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
//           <section
//             className="w-full  pr-[3%] pt-3 my-3"
//             style={{ backgroundColor: "#F7F7F7" }}
//           >
//             <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//               <ol className="list-none p-0 inline-flex mb-2">
//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
//                     Vendor
//                   </p>
//                   <ChevronRightIcon />
//                 </li>

//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 ">Update</p>
//                 </li>
//               </ol>
//             </nav>
//           </section>

//           <section className="gray_background">
//             <div className="container px-1 pb-2 mx-auto">
//               <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
//                 {loading ? (
//                   <Spinner loading={loading} />
//                 ) : (
//                   <form onSubmit={handleSubmit}>
//                     <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
//                       <input type="hidden" name="vendor_id" value={vendor_id} />

//                       <div className="mb-5">
//                         <label
//                           htmlFor="email"
//                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                         >
//                           Admin email
//                         </label>
//                         <div className="w-full">
//                           <input
//                             type="text"
//                             id="email"
//                             value={email}
//                             readOnly
//                             className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
//                           />
//                         </div>
//                       </div>
//                       <div className="w-full mb-4">
//                         <label
//                           htmlFor="status"
//                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                         >
//                           Status
//                         </label>
//                         <select
//                           id="status"
//                           value={status}
//                           onChange={(e) => setStatus(e.target.value)}
//                           className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
//                         >
//                           <option value="" disabled>
//                             Select status
//                           </option>
//                           <option value="1">Active</option>
//                           <option value="0">Inactive</option>
//                         </select>
//                       </div>
//                     </div>

//                     <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
//                       <Link
//                         to="/admin/vendor/list"
//                         className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
//                       >
//                         <List className="mr-2" />
//                         Back To List
//                       </Link>
//                       <button
//                         type="submit"
//                         className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
//                           loading ? "btn-loading" : "btn-primary"
//                         }`}
//                         disabled={loading}
//                       >
//                         {loading ? "Loading..." : "Update Product Stock"}
//                       </button>
//                     </div>
//                   </form>
//                 )}
//               </div>
//             </div>
//           </section>
//         </div>
//       </MainLayouts>
//     </div>
//   );
// };

// export default EditProductStock;

import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const EditProductStock = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [vendor_id, setVendor_id] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const navigateTo = useNavigate();
  const location = useLocation();
  const vendorId = location.state?.vendorId;

  useEffect(() => {
    if (vendorId) {
      // console.log("vendoe data", vendorId);
      setVendor_id(vendorId.vendor_id || "");
      setFirstName(vendorId.firstname || "");
      setLastName(vendorId.lastname || "");
      setPhoneNumber(vendorId.phone_number || "");
      setEmail(vendorId.email || "");
      setStatus(vendorId.status || "");
      setLoading(false);
    }
  }, [vendorId]);


  //axios api encryption call
  const axios = AxiosApiCall();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      if (!vendor_id || !status) {
        toast.error("All fields are required.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);
    const formData = { status, vendor_id };
    // console.log("this is form data for vendor update", formData);

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.put(ApiServices.updateVendor, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status: responseStatus, message } = response.data;
      if (responseStatus === 200) {
        navigateTo("/admin/vendor/list");
        toast.success(message);
        setStatus("");
        setVendor_id("");
      } else {
        handleError(
          { response: { status: responseStatus, data: { message } } },
          navigateTo
        );
      }
    } catch (error) {
      console.error("Error updating vendor:", error);
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendor
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Update</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                {loading ? (
                  <Spinner loading={loading} />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <input type="hidden" name="vendor_id" value={vendor_id} />

                      <div className="mb-5">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Admin email
                        </label>
                        <div className="w-full">
                          <input
                            type="text"
                            id="email"
                            value={email}
                            readOnly
                            className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                          />
                        </div>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          htmlFor="status"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Status
                        </label>
                        <select
                          id="status"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        >
                          <option value="" disabled>
                            Select status
                          </option>
                          <option value={vendorId.status}>
                            {vendorId.status}
                          </option>
                          {/* <option value="pending">Pending</option> */}
                          <option value="active">Active</option>
                          <option value="blocked">Blocked</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                      <Link
                        to="/admin/vendor/list"
                        className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                      >
                        <List className="mr-2" />
                        Back To List
                      </Link>
                      <button
                        type="submit"
                        className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                          }`}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Update Vendor"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default EditProductStock;
