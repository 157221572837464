import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const TwoFactorAuth = () => {
  const [email, setEmail] = useState("");
  const [two_factor_status, setTwoFactorStatus] = useState("");
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const authData = localStorage.getItem("auth");
    if (authData) {
      try {
        const parsedData = JSON.parse(authData);
        const AdminEmail = parsedData.email;
        setEmail(AdminEmail);
      } catch (error) {
        toast.error("Error getting the admin email.");
      }
    } else {
      toast.error("Admin email not found.");
    }
  }, []); 

  //axios api encryption call
  const axios = AxiosApiCall();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      if (!two_factor_status) {
        toast.error("Select a notification status.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    const authData = JSON.parse(localStorage.getItem("auth"));
    const adminToken = authData?.token;

    setLoading(true);
    const formData = {
      two_factor_status,
    };
    //  console.log(formData);
    try {
      const token = adminToken;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.put(ApiServices.updateAdminTwoFa, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        setTwoFactorStatus("");
        navigateTo("/admin/account/2fa/update/status");
        toast.success(message);
     } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

 

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Admin
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">
                    Two Factor Authentication status
                  </p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Admin email
                    </label>
                    <div className="w-full">
                      <input
                        type="text"
                        id="email"
                        value={email}
                        readOnly
                        className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      />
                    </div>
                  </div>
                  <div className="w-full mb-4">
                    <label
                      htmlFor="status"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <select
                      id="two_factor_status"
                      value={two_factor_status}
                      onChange={(e) => setTwoFactorStatus(e.target.value)}
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    >
                      <option value="" disabled>
                        Select status
                      </option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>

                  <button
                    type="submit"
                    className={` mb-3 text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium w-full my-1 ${
                      loading ? "btn-loading" : "btn-primary"
                    }`}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Set Admin 2FA"}
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default TwoFactorAuth;
