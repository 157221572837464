import React, { useRef, useContext } from "react";
import NavBarLogo from "../../../assets/navbar-logo.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import { AuthContext } from "../../../AuthProvider/AuthProvider";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as LockFill } from "bootstrap-icons/icons/lock-fill.svg";
import { ReactComponent as UnLockFill } from "bootstrap-icons/icons/unlock-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import AxiosApiCall from "../../../api/AxiosApiCall";
const VerifyOTP = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const makePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const makePasswordVisibilityTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };

  const makeConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  //axios api encryption call
  const axios = AxiosApiCall();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      if (!email || !otp) {
        toast.error("All fields are  required.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);
    const formData = {
      email,
      otp,
    };
    console.log("this is my form data for password update", formData);

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(ApiServices.forgetPassword, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        setEmail("");
        navigateTo("/admin/password/forget");
        toast.success(message);
      } else {
        handleError(status, message);
      }
    } catch (error) {
      console.error("Error updating  password:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //handle error response

  const handleError = (status, message) => {
    if (status === 300) {
      toast.error(message);
    } else if (status === 302) {
      localStorage.removeItem("auth");
      toast.error(message);
      navigateTo("/");
    } else if (status === 400) {
      toast.error(message);
      navigateTo("/admin/password/forget");
    } else if (status === 401) {
      localStorage.removeItem("auth");
      toast.error(message);
      navigateTo("/");
    } else if (status === 422) {
      toast.error(message);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Password
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 ">
                    Verify One time password(OTP)
                  </p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form className=" mx-auto " onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <div className="relative">
                      <input
                        type={showPasswordTwo ? "text" : "email"}
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Enter email address"
                      />
                    </div>
                  </div>

                  <div className="mb-5 w-full">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      OTP
                    </label>
                    <div className="relative">
                      <input
                        type={showPasswordTwo ? "number" : "otp"}
                        id="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Enter one time password(otp)"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium w-full my-1 ${
                      loading ? "btn-loading" : "btn-primary"
                    }`}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Verify One Time Password"}
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default VerifyOTP;
