import axios from "axios";
import CryptoJS from "crypto-js";

const axiosInstance = axios.create();


const AxiosApiCall = () => {
    const encryptData = (data) => {
        //console.log("this is the sent data:", JSON.stringify(data));
        const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
        // 32 hex characters
        const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV_KEY);

        

        const encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            CryptoJS.enc.Utf8.parse(secretKey),
            {
                iv: iv,
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC,
            }
        );

        return encrypted.toString();
    };



    // Add a request interceptor
    axiosInstance.interceptors.request.use(

        (config) => {

            const isEncryptionEnabled = process.env.REACT_APP_ENCRYPTION_ENABLED === 'true';

            if (config.method === "post" && isEncryptionEnabled && config.data && !config.data._isEncrypted) {
                if (
                    config.data &&
                    !config.data._isEncrypted &&
                    !(config.data instanceof FormData)
                ) {
                    const encryptedData = encryptData(config.data);
                    config.data = { request_data: encryptedData, _isEncrypted: true };

                    config.headers["Content-Type"] = "application/json";
                } else if (
                    config.data instanceof FormData &&
                    !config.data.has("_isEncrypted")
                ) {
                    const encryptedData = encryptData(config.data);

                    config.data = { request_data: encryptedData };
                    config.data.append("_isEncrypted", "true");

                    config.headers["Content-Type"] = "multipart/form-data";
                }
            } else {
                config.headers["Content-Type"] = "application/json";

                if (config.data instanceof FormData) {
                    config.headers["Content-Type"] = "multipart/form-data";
                }
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default AxiosApiCall;