import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const EditProduct = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [sub_category_id, setSubCategoryId] = useState("");
  const [product_category_id, setProductCategoryId] = useState("");
  const [product_id, setProductId] = useState("");
  const [status, setStatus] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubcategoryLoading, setIsSubcategoryLoading] = useState(false);
  const [loadProductCat, setLoadProductCat] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  // const [isSerialize, setIsSerialize] = useState(false);

  const navigateTo = useNavigate();
  const location = useLocation();
  const productByRow = location.state?.productByRow;

  // Effect to prepopulate form fields
  useEffect(() => {
    if (productByRow) {
      // console.log("product data coming from row list", productByRow);
      setName(productByRow.name || "");
      setDescription(productByRow.description || "");
      setCategoryId(productByRow.category_id || "");
      setSubCategoryId(productByRow.sub_category_id || "");
      setProductCategoryId(productByRow.product_category_id || "");
      setProductId(productByRow.product_id || "");
      setStatus(productByRow.status || "");
      // setIsSerialize(productByRow.is_serialized === 1);
    }
  }, [productByRow]);
  // Fetch categories when the component mounts

  useEffect(() => {
    const fetchCategories = async (category_id) => {
      setCategoryLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("auth"))?.token;
        if (!token) throw new Error("No access token found.");

        const response = await axios.get(ApiServices.getCategory, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const { status, data, message } = response.data;

        if (status === 200 && Array.isArray(data)) {
          setCategories(data);
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setCategoryLoading(false);
      }
    };

    fetchCategories();
  }, [productByRow]);

  // Fetch subcategories and product categories based on selected options

  useEffect(() => {
    if (category_id) fetchSubCategories(category_id);
  }, [category_id]);

  useEffect(() => {
    if (sub_category_id) fetchProductCategories(category_id, sub_category_id);
  }, [sub_category_id]);

  const fetchSubCategories = async (category_id) => {
    setIsSubcategoryLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.token;
      if (!token) throw new Error("No access token found.");

      const response = await axios.get(ApiServices.getSubCategoryById, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { category_id },
      });

      const { status, data, message } = response.data;
      if (status === 200 && Array.isArray(data)) {
        setSubCategory(data);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setIsSubcategoryLoading(false);
    }
  };

  const fetchProductCategories = async (category_id, sub_category_id) => {
    setLoadProductCat(true);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.token;
      if (!token) throw new Error("No access token found.");

      const response = await axios.get(ApiServices.getProducts, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { category_id, sub_category_id },
      });

      const { status, data, message } = response.data;
      if (status === 200 && Array.isArray(data)) {
        setProductCategory(data);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoadProductCat(false);
    }
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
    setSubCategoryId("");
    setProductCategoryId("");
    setProductCategory([]);
  };

  const handleSubcategoryChange = (e) => {
    setSubCategoryId(e.target.value);
    setProductCategoryId("");
  };

  const handleProductChange = (e) => {
    setProductCategoryId(e.target.value);
  };


  //axios api encryption call
  const axios = AxiosApiCall();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !product_category_id ||
      !category_id ||
      !sub_category_id ||
      !description
    ) {
      toast.error("All fields are required.");
      return;
    }

    setLoading(true);
    const formData = {
      name,
      product_category_id,
      category_id,
      sub_category_id,
      description,
      product_id,
      status,
      // is_serialize: isSerialize ? 1 : 0,
    };
    // console.log(formData);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.token;
      if (!token) throw new Error("No access token found.");

      const response = await axios.put(ApiServices.updateProducts, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/products");
        toast.success(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayouts>
      <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
        <section
          className="w-full pr-[3%] pt-3 my-3"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <nav className="bg-gray-200 py-0 px-4 rounded-lg">
            <ol className="list-none p-0 inline-flex mb-2">
              <li className="flex items-center">
                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                  Vendors Product
                </p>
                <ChevronRightIcon />
              </li>
              <li className="flex items-center">
                <p className="text-orange1">Update</p>
              </li>
            </ol>
          </nav>
        </section>

        <section className="gray_background">
          <div className="container px-1 pb-2 mx-auto">
            <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
              {loading ? (
                <Spinner loading={loading} />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <input type="hidden" name="product_id" value={product_id} />
                    <input type="hidden" name="status" value={status} />
                    <div className="w-full">
                      <label
                        htmlFor="category_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Category
                      </label>
                      <select
                        id="category_id"
                        name="category_id"
                        value={category_id}
                        onChange={handleCategoryChange}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          {categoryLoading ? "Loading..." : "Select Category"}
                        </option>
                        {categories.map((category, index) => (
                          <option key={index} value={category.category_id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="sub_category_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Subcategory
                      </label>
                      <select
                        id="sub_category_id"
                        name="sub_category_id"
                        value={sub_category_id}
                        onChange={handleSubcategoryChange}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          {isSubcategoryLoading
                            ? "Loading..."
                            : "Select Subcategory"}
                        </option>
                        {subCategory.map((subcategory, index) => (
                          <option
                            key={index}
                            value={subcategory.sub_category_id}
                          >
                            {subcategory.sub_category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-2">
                    <div className="w-full">
                      <label
                        htmlFor="product_category_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Product Category
                      </label>
                      <select
                        id="product_category_id"
                        name="product_category_id"
                        value={product_category_id}
                        onChange={handleProductChange}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          {loadProductCat
                            ? "Loading..."
                            : "Select Product Category"}
                        </option>
                        {productCategory.map((product, index) => (
                          <option
                            key={index}
                            value={product.product_category_id}
                          >
                            {product.product_category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                  </div>
                    {/* <div className="w-full my-2 mx-2">
                       <input
                        type="checkbox"
                        id="is_serialize"
                        name="is_serialize"
                        checked={isSerialize}
                        onChange={(e) => setIsSerialize(e.target.checked)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      /> 
                      <label
                        htmlFor="is_serialize"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Is Serialize <small className="text-red1  text-xs px-1"> (This is optional)</small>
                      </label>
                    </div> */}
                  <div className="grid grid-cols-12 sm:grid-cols-12 gap-6 mt-4">
                    <div className="col-span-12">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={6}
                        placeholder="Product Description"
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/products"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Update Product"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </MainLayouts>
  );
};

export default EditProduct;
