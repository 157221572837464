import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApiServices from "../../api/ApiServices";
import axios from "axios";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import AxiosApiCall from "../../api/AxiosApiCall";


const UpdateAppSettings = () => {
    const navigateTo = useNavigate();
    const location = useLocation();

    const sett = location.state?.sett;

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (sett) {
            // console.log("this is the app setting", sett);
            setId(sett.id || "");
            setName(sett.name || "");
            setValue(sett.value || "");
        }
    }, [sett]);


    //axios api encryption call
    const axios = AxiosApiCall();

    const handleUpdate = async (e) => {
        e.preventDefault();

        // Validate the form
        if (!value) {
            toast.error("Value field is required.");
            return;
        }

        setLoading(true);

        const formData = {};
        if (name) formData[name] = value;

        // console.log("update the app settings here",formData);

        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            if (!token) {
                throw new Error("No access token found.");
            }

            const response = await axios.put(
                ApiServices.UpdateAppSettings,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            const { status, message } = response.data;
            if (status === 200) {
                navigateTo("/admin/appsettings/list");
                toast.success(message, { toastId: "appsettings-update-success" });
                setName("");
                setValue("");
            } else {
                handleError({ response: { status, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    // Fields that should use a textarea
    const textareaFields = ["address", "about us"];

    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section
                        className="w-full  pr-[3%] pt-3 my-3"
                        style={{ backgroundColor: "#F7F7F7" }}
                    >
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                        Product Property
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-orange1">Update</p>
                                </li>
                            </ol>
                        </nav>
                    </section>

                    <section className="gray_background">
                        <div className="container px-1 pb-2 mx-auto">
                            <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                                <form onSubmit={handleUpdate} >
                                    <input type="hidden" name="id" value={id} />
                                    <div className="mb-4">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="border border-grey3 p-2 rounded-md w-full"
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="value" className="block text-sm font-medium text-gray-700">Value</label>

                                        {/* Conditionally render textarea or input based on the field name */}
                                        {textareaFields.includes(name.toLowerCase()) ? (
                                            <textarea
                                                id="value"
                                                name="value"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                                className="border border-grey3 p-2 rounded-md w-full"
                                                rows="4"
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                id="value"
                                                name="value"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                                    className="border border-grey3 p-2 rounded-md w-full"
                                            />
                                        )}
                                    </div>

                                    <div className="flex justify-end">
                                        <Link
                                            to="/admin/appsettings/list"
                                            className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                                        >
                                            <List className="mx-2" />
                                            Back To List
                                        </Link>
                                        <button
                                            type="submit"
                                            className={`text-dGrey3 bg-darkGreen3 p-2  mx-2 rounded-md ${loading ? "cursor-not-allowed" : ""}`}
                                            disabled={loading}
                                        >
                                            {loading ? "Updating..." : "Update Setting"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </MainLayouts>
        </div>
    );
};

export default UpdateAppSettings;
