import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApiServices from "../../api/ApiServices";
import axios from "axios";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import AxiosApiCall from "../../api/AxiosApiCall";

const EditRider = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const { riders } = location.state || {};

  const [firstname, setFirstName] = useState(riders?.firstname || "");
  const [lastname, setLastName] = useState(riders?.lastname || "");
  const [email, setEmail] = useState(riders?.email || "");
  const [status, setStatus] = useState(riders?.status || "");
  const [rider_id, setRider_id] = useState(riders?.riders_id || "");
  const [fullname, setFullname] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (riders) {
      // console.log("this is riders info", riders);
      setFirstName(riders.firstname || "");
      setLastName(riders.lastname || "");
      setEmail(riders.email || "");
      // setPhoneNumber(riders.phone_number || "");
      setStatus(riders.status || "");
      setRider_id(riders.rider_id || "");
    }
  }, [riders]);

  useEffect(() => {
    // Update the fullname changes occur 
    setFullname(`${firstname} ${lastname}`);
  }, [firstname, lastname]);


  //axios api encryption call
  const axios = AxiosApiCall();
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!status) {
      toast.error("Status field is required.");
      return;
    }

    setLoading(true);

    const formData = {
      status,
      rider_id
    };
    // console.log("form data for update riders status",formData);

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.put(
        ApiServices.EditRiders,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/riders/list");
        toast.success(message);
        setStatus("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Rider
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Update</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <input type="hidden" name="riders_id" value={rider_id} />
                  <div className="d-flex">
                    <div className="w-full mb-3">
                      <label
                        htmlFor="firstname"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="fullname"
                        value={fullname}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        readOnly
                      />
                    </div>
                    <div className="w-full mb-3">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Email
                      </label>

                      <input
                        type="email"
                        id="email"
                        value={email}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        readOnly
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="status"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Status
                      </label>
                      <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          Select status
                        </option>
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                        <option value="blocked">Blocked</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/riders/list"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                        }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Update Admin"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default EditRider;
