import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const UpdatePermission = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const permission = location.state?.permission;
  // console.log("this is the single  permission", permission);

  // States for form fields
  const [permission_id, setPermissionId] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (permission) {
      // console.log("single permission", permission);
      setName(permission.name || "");
      setPermissionId(permission.permission_id || "");
    }
  }, [permission]);

  // Validation function
  const validateForm = () => {
    if (!permission_id || !name) {
      toast.error("All fields are required.");
      return false;
    }
    return true;
  };


  //axios api encryption call
  const axios = AxiosApiCall();

  // Handle submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const formData = {
      permission_id: permission_id,
      name,
    };

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.put(
        ApiServices.updatePermissions,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/permission/list");
        toast.success(message, { toastId: "Permission-updated-successfully" });
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section className="body-font">
            <div className="container px-5 pb-2 mx-auto">
              <section
                className="w-full pr-[3%] pt-3 my-3"
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                  <ol className="list-none p-0 inline-flex mb-2">
                    <li className="flex items-center">
                      <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                        Admin
                      </p>
                      <ChevronRightIcon />
                    </li>
                    <li className="flex items-center">
                      <p className="text-orange1">Update Permission</p>
                    </li>
                  </ol>
                </nav>
              </section>

              <div className="lg:w-full md:w-full bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  {/* Permission ID */}
                  <div className="w-full mb-3">
                    <input
                      type="hidden"
                      id="permission_id"
                      value={permission_id}
                      onChange={(e) => setPermissionId(e.target.value)}
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    />
                  </div>

                  {/* Permission Name */}
                  <div className="w-full mb-3">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Permission Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      // to="/admin/roles/view/permission"
                      to="/admin/permission/list"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>

                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Update Permission"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default UpdatePermission;
