
import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as CloudUploadFill } from "bootstrap-icons/icons/cloud-upload-fill.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import Spinner from "../../spinner/Spinner";
import { ReactComponent as XSquareFill } from "bootstrap-icons/icons/x-square-fill.svg";
import AxiosApiCall from "../../../api/AxiosApiCall";
const AddImagesForm = ({ formData, setFormData, handleNext, name, productCategory }) => {
    const { stock_id: initialStockId, images: initialImages } = formData;
    const [images, setImages] = useState(initialImages || []);
    const [loading, setLoading] = useState(false);
    const [stockId, setStockId] = useState(initialStockId || "");
    const [product_category, setProductcategory] = useState(formData.product_category || "");

    const navigate = useNavigate();

    useEffect(() => {
        setStockId(initialStockId);
    }, [initialStockId]);

    const validateForm = () => {
        if (!images.length) {
            toast.error("At least one image is required.");
            return false;
        }
        if (!stockId) {
            toast.error("Stock ID is required.");
            return false;
        }
        return true;
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const validateFile = (file) => {
        if (!file.type.startsWith("image/")) {
            toast.info("Only image files are allowed.");
            return false;
        }
        if (file.size > 5 * 1024 * 1024) {
            toast.info("File size exceeds 5MB limit.");
            return false;
        }
        return true;
    };

    const handleFileChange = useCallback(async (e) => {
        const filesArray = Array.from(e.target.files);
        if (filesArray.length + images.length > 6) {
            toast.error("You can only upload a maximum of 6 images.");
            return;
        }
        const base64Images = [];

        for (const file of filesArray) {
            if (!validateFile(file)) continue;
            try {
                const base64 = await convertToBase64(file);
                base64Images.push(base64);
            } catch {
                toast.error("Failed to convert image.");
            }
        }
        setImages((prevImages) => [...prevImages, ...base64Images]);
    }, [images]);

    const handleImageRemove = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const renderImagePreviews = () => {
        return images.map((image, index) => (
            <div key={index} className="relative w-20 h-20">
                <img src={image} alt={`Preview ${index}`} className="w-full h-full object-cover rounded" />
                <button
                    onClick={() => handleImageRemove(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                    title="Remove image"
                >
                    <XSquareFill className="w-5 h-5 text-red1" />
                    {/* &times; */}
                </button>
            </div>
        ));
    };


    //axios api encryption call
    const axios = AxiosApiCall();

    const handleImageUpload = async (imageData) => {
        const { stock_id } = formData; // Extract stock_id from formData

        if (!imageData || !stock_id) {
            toast.error("Image data and Stock ID are required.");
            return;
        }

        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;

            if (!token) {
                throw new Error("No access token found.");
            }

            const formDataToUpload = {
                stock_id: stock_id,
                images: imageData.map((image) => image.split(",")[1]), // Assuming imageData is in base64 format
            };

            const response = await axios.post(ApiServices.addImagesToProductStock, formDataToUpload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            const { status, message } = response.data;

            if (status === 200) {
                toast.success(message);
                navigate("/admin/products/stock/list");
                setImages([]); // Reset images after successful submission
            } else {
                handleError({ response: { status, data: { message } } });
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true);
        await handleImageUpload(images); // Call the image upload function
        setLoading(false);
    }, [images, validateForm]);

    return (
        <>
            {loading ? (
                <Spinner loading={loading} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <h2 className="text-orange1">Add Product</h2>
                    <input type="hidden" name="stock_id" value={stockId || ""} />
                    <div className="flex flex-col md:flex-row gap-6 mt-4">
                        <div className="flex-1">
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Name
                            </label>
                            <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                                {formData.name}
                            </div>
                        </div>
                        <div className="flex-1">
                            <label htmlFor="productCategory" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Product Category Name
                            </label>
                            <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                                {formData.product_category}
                            </div>
                        </div>
                    </div>

                    <div className="mt-6">
                        {loading && (
                            <div className="mb-4 text-sm text-orange1" aria-live="polite">
                                Loading, please wait...
                            </div>
                        )}
                        <div className="flex flex-col my-4">
                            {images.length > 0 && <div className="flex space-x-2">{renderImagePreviews()}</div>}
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="images"
                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-orange1 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <CloudUploadFill className="mr-2 w-8 h-8 text-orange1" />
                                    <p className="mb-2 text-sm text-darkGreen3">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                </div>
                                <input
                                    id="images"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleFileChange}
                                    className="hidden"
                                />
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                        <Link
                            to="/admin/products/stock/list"
                            className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                        >
                            <List className="mr-2" />
                            Back To List
                        </Link>
                        <button
                            type="submit"
                            className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"}`}
                            disabled={loading}
                            title="Submit images"
                        >
                            Add Image
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default AddImagesForm;
