import React, { useState, useEffect, useCallback } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as CloudUploadFill } from "bootstrap-icons/icons/cloud-upload-fill.svg";
import { ReactComponent as XSquareFill } from "bootstrap-icons/icons/x-square-fill.svg";

import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const AddImagesToProduct = () => {
  const [images, setImages] = useState([]);
  const [productId, setProductId] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [productCategoryName, setProductCategoryName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const productByRow = location.state?.productByRow;

  useEffect(() => {
    if (productByRow) {
      setName(productByRow.name || "");
      setProductCategoryName(productByRow.product_category_name || "");
      setProductId(productByRow.product_id || "");
    }
  }, [productByRow]);



  //convert images to base 64
  // const convertToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };

  // const handleFileChange = async (e) => {
  //   const filesArray = Array.from(e.target.files);
  //   const base64Images = [];

  //   for (const file of filesArray) {
  //     if (!file.type.startsWith("image/")) {
  //       toast.info("Only image files are allowed.");
  //       continue; // Skip non-image files
  //     }
  //     if (file.size > 5 * 1024 * 1024) {
  //       // limit image size to 5MB
  //       toast.error("File size exceeds 5MB limit.");
  //       continue; // Skip files that are too large
  //     }
  //     try {
  //       const base64 = await convertToBase64(file);
  //       base64Images.push(base64);
  //     } catch (error) {
  //       toast.error("Failed to convert image.");
  //     }
  //   }
  //   setImages(base64Images);
  // };

  // Preview of selected images


  // const renderImagePreviews = () => {
  //   return images.map((image, index) => (
  //     <img
  //       key={index}
  //       src={image}
  //       alt={`Preview ${index}`}
  //       className="w-20 h-20 object-cover"
  //     />
  //   ));
  // };


  const validateForm = () => {
    if (!images.length || !productId) {
      toast.error("The image field is required.");
      return false;
    }
    return true;
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const validateFile = (file) => {
    if (!file.type.startsWith("image/")) {
      toast.info("Only image files are allowed.");
      return false;
    }
    if (file.size > 5 * 1024 * 1024) {
      toast.info("File size exceeds 5MB limit.");
      return false;
    }
    return true;
  };

  const handleFileChange = useCallback(async (e) => {
    const filesArray = Array.from(e.target.files);
    if (filesArray.length + images.length > 6) {
      toast.error("You can only upload a maximum of 6 images.");
      return;
    }
    const base64Images = [];

    for (const file of filesArray) {
      if (!validateFile(file)) continue;
      try {
        const base64 = await convertToBase64(file);
        base64Images.push(base64);
      } catch {
        toast.error("Failed to convert image.");
      }
    }
    setImages((prevImages) => [...prevImages, ...base64Images]);
  }, [images]);

  const handleImageRemove = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const renderImagePreviews = () => {
    return images.map((image, index) => (
      <div key={index} className="relative w-20 h-20">
        <img src={image} alt={`Preview ${index}`} className="w-full h-full object-cover rounded" />
        <button
          onClick={() => handleImageRemove(index)}
          className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
          title="Remove image"
        >
          <XSquareFill className ="w-5 h-5 text-red1" />
          {/* &times; */}
        </button>
      </div>
    ));
  };



  //axios api encryption call
  const axios = AxiosApiCall();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) throw new Error("No access token found.");


      const formData = {
        product_id: productId,
        images: images.map((image) => {
          // Split to remove 'data:image/png;base64,' part
          return image.split(",")[1];
        }),
      };

      // Log to check the payload before sending
      // console.log("FormData before submission:", formData);

      const response = await axios.post(
        ApiServices.addProductsImages,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message);
        navigate("/admin/products");
        setImages([]); // Reset image data
        setName(""); // Reset name field
        setProductCategoryName(""); // Reset category field
      } else {
        handleError({ response: { status, data: { message } } }, navigate);
      }
    } catch (error) {
      handleError(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayouts>
      <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
        <section
          className="w-full pr-[3%] pt-3 my-3"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <nav className="bg-gray-200 py-0 px-4 rounded-lg">
            <ol className="list-none p-0 inline-flex mb-2">
              <li className="flex items-center">
                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                  Add Vendors Product
                </p>
                <ChevronRightIcon />
              </li>
              <li className="flex items-center">
                <p className="text-orange1">Images</p>
              </li>
            </ol>
          </nav>
        </section>

        <section className="gray_background">
          <div className="container px-1 pb-2 mx-auto">
            <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="product_id" value={productId} />
                <div className="flex flex-col md:flex-row gap-6 mt-4">
                  <div className="flex-1">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Name
                    </label>
                    {/* <input
                      type="text"
                      id="name"
                      value={name}
                      readOnly
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    /> */}
                    <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                      {name}
                    </div>
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="productCategoryName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Product Category Name
                    </label>
                    <input
                      type="text"
                      id="productCategoryName"
                      value={productCategoryName}
                      readOnly
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    {/* <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                      {productCategoryName}
                    </div> */}
                  </div>
                </div>

                <div className="mt-6">
                  {loading && (
                    <div className="mb-4 text-sm text-orange1">
                      Loading, please wait...
                    </div>
                  )}
                  {/* Inside your return statement */}
                  <div className="flex flex-col my-4">
                    {images.length > 0 && (
                      <div className="flex space-x-2">
                        {renderImagePreviews()}
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="images"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-orange1 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <CloudUploadFill className="mr-2 w-8 h-8 text-orange1" />

                        <p className="mb-2 text-sm text-darkGreen3">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        {/* <p className="text-xs text-orange1 ">
                          SVG, PNG, JPG or GIF (MAX. 800x400px)
                        </p> */}
                      </div>
                      <input
                        id="images"
                        type="file"
                        accept="image/*"
                        multiple // Allow multiple file selection
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </label>
                  </div>
                </div>

                <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                  <Link
                    to="/admin/products"
                    className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                  >
                    <List className="mr-2" />
                    Back To List
                  </Link>
                  <button
                    type="submit"
                    className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                      }`}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Add To Product"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </MainLayouts>
  );
};

export default AddImagesToProduct;
