import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import ApiServices from "../../api/ApiServices";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import { handleError } from "../../components/utils/AuthenticationCheck";
import AxiosApiCall from "../../api/AxiosApiCall";

const CategoryList = () => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchCategoryList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) throw new Error("No access token found.");

        const response = await axios.get(ApiServices.getCategory, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log("this is the category lis data", data);
        if (status === 200) {
          setCategory(data.slice().reverse());
          toast.success(message, { toastId: "category-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryList();
  }, []);


  //axios api encryption call
  const axios = AxiosApiCall();

  const handleDeleteCategory = async (categoryId) => {
    const formData = { category_id: categoryId };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) throw new Error("No access token found.");

      const response = await axios.delete(ApiServices.removeCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const { status, message } = response.data;

      if (status === 200) {
        setCategory((prev) =>
          prev.filter((cat) => cat.category_id !== categoryId)
        );
        toast.success(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirmation = (categoryId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteCategory(categoryId);
      }
    });
  };

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const columns = [
    {
      name: "Actions",
      width: "300px",
      cell: (row) => (
        <div className="flex justify-start">
          <Link
            to="/admin/subcategory/list"
            state={{ categoryId: row }}
            className="mx-4 my-2 group"
            style={{ marginLeft: "5px" }}
          >
            <Eye className="w-5 h-5 text-dash mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              subcategory
            </div>
          </Link>

          <Link
            to="/admin/category/update"
            state={{ category_id: row }}
            className="mx-4 my-2 group"
            style={{ marginLeft: "5px" }}
          >
            <PencilSquare className="w-5 h-5 text-dash4 mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Edit category
            </div>
          </Link>

          <Link
            className="mx-4 my-2 group"
            onClick={(e) => handleDeleteConfirmation(row.category_id)}
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete category"
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Delete category
            </div>
          </Link>
        </div>
      ),
    },

    {
      name: "Category Name",
      selector: (row) => (row.name ? row.name.toUpperCase() : "NOT AVAILABLE"),
      sortable: true,
      width: "300px",
    },

    {
      name: "Status",
      selector: (row) =>
        row.status ? row.status.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      cell: (row) => (
        <span className={row.status === "active" ? "text-dash" : "text-dash3"}>
          {row.status ? row.status.toUpperCase() : "NOT AVAILABLE"}
        </span>
      ),
      width: "300px",
    },
  ];

  const filteredItems = category.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    )
  );

  const data = filteredItems.map((category) => ({
    name: category.name || "NOT AVAILABLE",
    status: category.status || "NOT AVAILABLE",
    category_id: category.category_id,
  }));

  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Category
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/addcategory"
              className="w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Create category
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              responsive
              highlightOnHover
              noDataComponent={<div>No category found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default CategoryList;
